export interface TPARef {
  pageRef?: PageRef;
  pageUriSEO?: string;
  title?: string;
}

export interface TPAAppRef extends TPARef {
  instanceId: string;
}

export interface TPACompRef extends TPARef {
  compId: string;
}

export interface TPAComp {
  componentType: TPAComponentType;
  appDefinitionId?: string;
  copyStyle?: boolean;
  styleId?: string;
  widget?: {
    widgetId: string;
    allPages?: boolean;
    wixPageId?: string;
  };
  page?: {
    pageId: string;
    title?: string;
    isHidden?: boolean;
  };
}

export interface RouterDescr {
  prefix: string;
  config?: object;
  group?: string;
}

export interface PageRouter {
  routerRef: RouterRef;
  pageRef: PageRef;
  pageRoles?: string[];
}

export type PageType = 'MOBILE' | 'DESKTOP';

export interface ComponentRef {
  id: string;
  type: PageType;
  innerPath?: string[];
}

export interface Connection {
  role: string;
  controllerRef: ComponentRef;
  isPrimary?: boolean;
  config?: any;
}

export interface PageRef {
  id: string;
  type?: PageType;
}

export interface RouterRef {
  id: string;
}

export interface ComponentDefinition {
  componentType: string;
  data?: any;
  style?: any;
  layout?: any;
}

export interface PageData {
  title: string;
  id: string;
  type: string;
  hideTitle: boolean;
  icon: string;
  windowTitle: string;
  descriptionSEO: string;
  metaKeywordsSEO: string;
  pageTitleSEO: string;
  pageUriSEO: string;
  hidePage: boolean;
  underConstruction: boolean;
  tpaApplicationId: number;
  managingAppDefId: string;
  tpaPageId: string;
  pageSecurity: object;
  indexable: boolean;
  mobileHidePage: true;
  pageBackgrounds: object;
  isLandingPage: boolean;
  isMobileLandingPage: boolean;
  isPopup: boolean;
  translationData: object;
}

export enum TPAComponentType {
  Page = 'PAGE',
  Widget = 'WIDGET',
}
