import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';
import * as platformApp from './editor';

export const platformAppWithMA = withMembersArea(platformApp, {
  installAutomatically: false,
  membersAreaApps: [
    MA_APP_IDS.ABOUT,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SETTINGS,
    MA_APP_IDS.ALL_MEMBERS,
  ],
});
